export default [
  {
    label: `Open Cases`,
    key: "open",
    value: 0,
  },
  {
    label: "Assigned to Me",
    key: "assigned_to_me",
    value: 0,
  },
  {
    label: "Assigned to Sales",
    key: "assigned_to_sales",
    value: 0,
  },
  {
    label: `Quotation Sent`,
    key: "quotation_sent",
    value: 0,
  },
  {
    label: `Follow-up Required`,
    key: "follow_up",
    value: 0,
  },
  {
    label: `Payment Confirmation Pending`,
    key: "payment_confirmation",
    value: 0,
  },
  {
    label: `Converted`,
    key: "converted",
    value: 0,
  },
  {
    label: `Irrelevant`,
    key: "irrelevant",
    value: 0,
  },
];

export default [
  {
    label: `Pending Cases`,
    key: "sent_to_eca",
    value: 0,
  },
  {
    label: `Viewed Cases`,
    key: "viewed_by_eca",
    value: 0,
  },

  {
    label: `Downloaded Cases`,
    key: "downloaded_by_eca",
    value: 0,
  },
];

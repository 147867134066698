<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="rounded-xl"
    >
      <v-card :outlined="$vuetify.theme.dark" rounded="xl" height="500px">
        <v-card-title class="d-flex justify-space-between">
          <div class="title-name">Assign to {{ modalTitle }}</div>
          <v-btn
            icon
            @click="
              executiveModal({
                university_id: '',
                id: '',
                show: false,
                type: '',
              })
            "
          >
            <v-icon size="26">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <div class="mt-5 mx-5">
          <v-text-field
            v-model="search"
            clearable
            dense
            solo
            outlined
            flat
            label="Search name"
            prepend-inner-icon="mdi-magnify"
            color="primary"
            class="rounded-lg"
          ></v-text-field>
        </div>
        <v-card-text>
          <div class="d-flex align-center justify-center py-5" v-if="loading">
            <v-progress-circular
              :size="60"
              :width="4"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list v-else>
            <v-list-item-group active-class="primary--text">
              <template v-for="(item, index) in items">
                <v-list-item
                  :key="item.id"
                  @click="selectExecutive(item.id)"
                  two-line
                  outlined
                >
                  <v-list-item-avatar>
                    <v-avatar color="primary" size="40">
                      <v-img
                        v-if="item.profile_pic"
                        :src="item.profile_pic"
                        :lazy-src="blurImg"
                      />
                      <span v-else class="white--text font-weight-bold">
                        {{ item.name_initials }}
                      </span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.user.email || item.user.mobile"
                    >
                      {{ getUserTitle(item) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ getUserSubTitle(item) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon v-if="user.email === item.user.email">
                    <v-badge inline content="me"></v-badge>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      loading: false,
      blurImg: require("@/assets/images/blurImg.png"),
      search: "",
      items: [],
    };
  },

  computed: {
    ...mapGetters({
      getExecutiveModal: "caseManagement/getExecutiveModal",
      user: "getUser",
    }),

    show: {
      get() {
        return this.getExecutiveModal.show;
      },
      set(value) {
        this.executiveModal({ show: value });
      },
    },

    type() {
      return this.getExecutiveModal.type;
    },

    modalTitle() {
      if (this.type == "assign_sales") return "Sales";
      if (this.type == "assign_operations") return "Operations";
      if (this.type == "assign_fe_ir") return "FE/IR";
      if (this.type == "assign_vendor") return "Vendor";
      return "";
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.onModalOpen();
      } else this.onModalClosed();
    },

    search() {
      this.debounceSearch();
    },
  },

  created() {
    this.debounceSearch = _.debounce(function () {
      this.onModalOpen();
    }, 500);
  },

  methods: {
    ...mapActions({
      executiveModal: "caseManagement/executiveModal",
      showSnackbar: "app/showSnackbar",
    }),

    getUserTitle(item) {
      const list = [];

      if (item.user.email) {
        list.push(item.user.email);
      }

      if (item.user.mobile) {
        list.push(item.user.mobile_display);
      }

      return list.join(" | ");
    },

    getUserSubTitle(item) {
      const list = [];

      if (item.city) {
        list.push(item.city.name);

        if (item.city.state) {
          list.push(item.city.state.name);
        }
      }

      return list.join(", ");
    },

    onModalOpen() {
      if (this.type == "assign_sales") {
        this.getSalesList();
      }

      if (this.type == "assign_operations") {
        this.getOperationsList();
      }

      if (this.type == "assign_fe_ir") {
        this.getFeIrExecutiveList();
      }

      if (this.type == "assign_vendor") {
        this.getVendorList();
      }
    },

    onModalClosed() {
      this.loading = false;
      this.search = "";
      this.items = [];
    },

    getSalesList() {
      this.loading = true;
      let params = {
        list_type: "assign_sales",
        apply_pagination: false,
        query:
          "{user {id, email}, id, name, first_name, last_name, name_initials, profile_pic}",
      };

      if (this.search) params["search"] = this.search;

      const successHandler = (res) => {
        this.items = res.data.data;
      };
      const finallyHandler = () => {
        this.loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.team.list, {
        params: params,
        onSuccess: successHandler,
        onFinally: finallyHandler,
      });
    },

    getOperationsList() {
      this.loading = true;

      const params = {
        list_type: "assign_operations",
        university_id: this.getExecutiveModal.university_id,
        apply_pagination: false,
        query:
          "{user {id, email}, id, name, first_name, last_name, name_initials, profile_pic}",
      };

      if (this.search) params["search"] = this.search;

      const successHandler = (res) => {
        this.items = res.data.data;
      };

      const finallyHandler = () => {
        this.loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.team.list, {
        params: params,
        onSuccess: successHandler,
        onFinally: finallyHandler,
      });
    },

    getFeIrExecutiveList() {
      this.loading = true;

      const params = new URLSearchParams();
      params.append("list_type", "assign_fe_ir");
      params.append("university_id", this.getExecutiveModal.university_id);
      params.append("apply_pagination", false);
      params.append("search", this.search);
      params.append("role", "field_executive");
      params.append("role", "internal_resource");

      const successHandler = (res) => {
        this.items = res.data.data;
      };

      const finallyHandler = () => {
        this.loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.agent.list, {
        params: params,
        onSuccess: successHandler,
        onFinally: finallyHandler,
      });
    },

    getVendorList() {
      this.loading = true;

      const params = {
        apply_pagination: false,
        role: "vendor",
        search: this.search,
      };

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.agent.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    selectExecutive(selected_id) {
      this.$emit("selected", selected_id);
    },
  },
};
</script>
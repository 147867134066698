<template>
  <div>
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ $utils.formatDate(item.eca_status_datetime) }}
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div v-if="item.client">
          {{ item.client.name }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.university`]="{ item }">
        <div v-if="item.university">
          {{ item.university.name }}
          <div>
            {{ item.university.address }}
            <span v-if="item.university.city">
              {{ item.university.city.name }},
              <span>{{ item.university.city.state.name }}</span>
            </span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            v-for="action in btnActions(item)"
            :key="action.key"
            @click="actionHandler(action.key, item)"
          >
            <v-icon left v-if="action.icon">mdi-{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <document-viewer @closed="updateStatus('viewed')" />
  </div>
</template>

<script>
import _ from "lodash";
import { saveAs } from "file-saver";
import { mapActions, mapGetters } from "vuex";
import { ecaHeaders } from "@/headers/caseManagement";

import DocumentViewer from "@/components/Dialogs/DocumentViewer";

export default {
  components: { DocumentViewer },
  data() {
    return {
      loading: false,
      items: [],

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_count: 10,

      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters({
      search: "caseManagement/search",
      filters: "caseManagement/filters",
      activeTab: "caseManagement/activeTab",
    }),

    headers() {
      return ecaHeaders;
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    page_count() {
      this.getCaseList();
    },

    page_number() {
      this.getCaseList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    this.updateRoute();
  },

  mounted() {
    this.getCaseList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      setSearch: "caseManagement/setSearch",
      setFilters: "caseManagement/setFilters",
      toggleDocumentViewer: "dialogs/toggleDocumentViewer",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getCaseList();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    btnActions(item) {
      return this.$_.filter(item.btn_actions, { show_in_menu: false });
    },

    actionHandler(key, item) {
      switch (key) {
        case "eca_view_doc": {
          this.viewDocuments(item);
          this.selectedItem = item;
          break;
        }

        case "eca_download_doc": {
          this.downloadDocuments(item);
          this.selectedItem = item;
          break;
        }

        default:
          break;
      }
    },

    viewDocuments(item) {
      const documents = item.documents.map((document) => {
        return {
          url: document.file,
          name: document.name,
        };
      });

      this.toggleDocumentViewer({
        show: true,
        items: documents,
      });
    },

    downloadDocuments(item) {
      this.showSnackbar({
        text: "Please wait, download is in progress.",
        color: "success",
      });

      const params = {
        case_id: item.id,
      };

      const onSuccess = (res) => {
        let filename = "documents.pdf";
        if (res.headers["content-disposition"])
          [, filename] = res.headers["content-disposition"].split("filename=");
        saveAs(res.data, filename);
        this.updateStatus("downloaded");
      };

      const onFailure = () => {
        this.showSnackbar({
          text: "Something went wrong",
          color: "error",
        });
      };

      return this.$request(this.$keys.GET, this.$urls.case.document.download, {
        params,
        onSuccess,
        onFailure,
        responseType: "blob",
      });
    },

    updateStatus(status) {
      const item = this.selectedItem;

      if (status === "viewed" && item.eca_status === "sent") {
        this.changeStatus(item.id, "viewed");
      }

      if (status === "downloaded" && item.eca_status === "viewed") {
        this.changeStatus(item.id, "downloaded");
      }
    },

    changeStatus(caseId, status) {
      const onSuccess = () => {
        this.reloadData();
      };

      const params = {
        case_id: caseId,
        status: status,
      };

      return this.$request(this.$keys.GET, this.$urls.case.changeEcaStatus, {
        params,
        onSuccess,
      });
    },

    getCaseList() {
      this.items = [];
      this.loading = true;

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        case_status: this.activeTab.key,
        search: this.search,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },
  },
};
</script>
<template>
  <v-sheet outlined v-if="item">
    <v-toolbar flat dense>
      <v-toolbar-title>{{ item.name }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        width="35"
        height="35"
        icon
        :disabled="page <= 1"
        @click="page -= 1"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        width="35"
        height="35"
        icon
        :disabled="page === numPages"
        @click="page += 1"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="rotation -= 90">
        <v-icon>mdi-rotate-left</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="rotation += 90">
        <v-icon>mdi-rotate-right</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="download(item.url, item.name)">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon :href="item.url" target="_blank">
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-sheet :style="{ width: width, height: height }" style="overflow: auto">
      <vue-pdf-embed
        :source="item.url"
        style="width: 100%"
        :page="page"
        :rotation="rotation"
        @loaded="onPdfLoaded"
      />
    </v-sheet>
  </v-sheet>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { saveAs } from "file-saver";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      numPages: 1,
      page: 1,
      rotation: 0,
    };
  },
  unmounted() {
    this.numPages = 1;
    this.page = 1;
    this.rotation = 0;
  },
  methods: {
    onPdfLoaded(e) {
      this.numPages = e._pdfInfo.numPages;
    },
    download(fileUrl, fileName) {
      saveAs(fileUrl, fileName);
    },
  },
};
</script>
<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      width="1000px"
      content-class="rounded-xl"
    >
      <v-card height="650px" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-text class="py-0 px-0 d-flex" v-if="show">
          <v-navigation-drawer permanent width="20%">
            <v-list nav class="ma-1">
              <v-list-item-group
                v-model="vModel"
                color="primary"
                active-class="outlined-active"
                no-action
                mandatory
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  class="outlined"
                >
                  <v-list-item-content class="py-2">
                    <v-img
                      :src="item.url"
                      height="100"
                      width="100%"
                      v-if="item.type === 'image'"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-sheet
                      v-if="item.type === 'pdf'"
                      height="100"
                      width="100%"
                      class="d-flex justify-center"
                    >
                      <v-icon size="60">mdi-file-pdf-box</v-icon>
                    </v-sheet>
                    <v-list-item-subtitle class="mt-1">
                      {{ item.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
          <v-sheet width="80%">
            <v-toolbar flat>
              <v-toolbar-title>
                <div v-if="title || subtitle">
                  <div v-if="title">{{ title }}</div>
                  <div class="text-subtitle-2" v-if="subtitle">
                    {{ subtitle }}
                  </div>
                </div>
                <div v-else>Document Viewer</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="modalClosed">
                <v-icon size="22">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
            <v-container>
              <ImageViewer
                :item="selectedItem"
                height="500px"
                width="776px"
                v-if="selectedItem.type === 'image'"
              />
              <PdfViewer
                height="500px"
                width="100%"
                :item="selectedItem"
                v-if="selectedItem.type === 'pdf'"
              />
            </v-container>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import { mapActions, mapGetters } from "vuex";
import ImageViewer from "@/components/ImageViewer";
import PdfViewer from "@/components/PdfViewer";

export default {
  components: {
    ImageViewer,
    PdfViewer,
  },
  data() {
    return {
      vModel: 0,
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  computed: {
    ...mapGetters({
      documentViewer: "dialogs/documentViewer",
    }),
    show: {
      get() {
        return this.documentViewer.show;
      },
      set(value) {
        this.toggleDocumentViewer({ show: value });
      },
    },

    title() {
      return this.documentViewer.title;
    },

    subtitle() {
      return this.documentViewer.subtitle;
    },

    selectedItem() {
      return this.items[this.vModel];
    },

    items() {
      return this.documentViewer.items.map((item) => {
        if (typeof item === "object") {
          return {
            url: item.url,
            type: this.$utils.getFileTypeFromUrl(item.url),
            name: item.name,
          };
        } else {
          return {
            url: item,
            type: this.$utils.getFileTypeFromUrl(item),
            name: this.$utils.getFilenameFromUrl(item),
          };
        }
      });
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      toggleDocumentViewer: "dialogs/toggleDocumentViewer",
    }),
    openModal() {},
    modalClosed() {
      this.toggleDocumentViewer({
        show: false,
      });
      this.vModel = 0;
      this.$emit("closed");
    },
  },
};
</script>
<style scoped>
.outlined {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--v-primary-lighten5);
}
.outlined-active {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--v-primary-base);
}
</style>



<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
  >
    <!-- loader -->
    <Loading v-if="loading" />

    <!-- dialog -->
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <!-- dialog title -->
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Add Case</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <!-- dialog content -->
      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <v-text-field
            type="text"
            :rules="[$rules.required]"
            v-model="form.name"
            outlined
            dense
            label="Student Name"
            class="rounded-lg"
          ></v-text-field>

          <div class="d-flex" style="gap: 20px">
            <div style="width: 85px">
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="selectedCountry"
                :items="country_list"
                item-text="name"
                return-object
                auto-select-first
                dense
                hide-details
                outlined
                @input="validate_number"
                class="rounded-lg"
              >
                <template v-slot:selection="data">
                  <span class="mr-2">
                    <v-img
                      :src="data.item.flag"
                      height="20"
                      width="25"
                      class="rounded"
                      v-if="data.item.flag"
                    ></v-img>
                    <div v-else>
                      {{ data.item.iso2 }}
                    </div>
                  </span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      <v-img
                        :src="data.item.flag"
                        height="20"
                        max-width="25"
                        class="rounded mr-2"
                        v-if="data.item.flag"
                      ></v-img>
                      <div v-else class="mr-2">
                        {{ data.item.iso2 }}
                      </div>
                      {{ data.item.name }}
                      ({{ data.item.phone_code }})
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </div>

            <v-text-field
              v-model="form.mobile"
              label="Mobile Number"
              :rules="[$rules.required, isMobileValid]"
              @input="validate_number"
              hide-spin-buttons
              type="number"
              outlined
              dense
              @keydown="$rules.blockInvalidChar"
              :prefix="selectedCountry ? selectedCountry.phone_code : ''"
              class="rounded-lg"
            >
            </v-text-field>
          </div>

          <v-text-field
            v-model="form.email"
            label="Email Address"
            :rules="[$rules.required, $rules.email]"
            height="42px"
            type="email"
            outlined
            dense
            class="rounded-lg"
          ></v-text-field>

          <v-autocomplete
            auto-select-first
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            :rules="[$rules.required]"
            :search-input.sync="eca_search"
            :loading="eca_list_loading"
            v-model="form.eca"
            :items="eca_list"
            item-text="name"
            item-value="id"
            flat
            outlined
            dense
            multiple
            label="ECA Body"
            class="rounded-lg"
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span v-if="eca_list_loading">Searching...</span>
                <span v-else> No data available </span>
              </div>
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="form.eca_reference_number"
            label="ECA Reference No."
            type="text"
            outlined
            dense
            class="rounded-lg"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider />

      <!-- modal actions -->
      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-8 rounded-lg"
          :loading="btnLoading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,

      caseDetail: null,
      selectedCountry: "",
      isMobileValid: true,

      eca_list: [],
      eca_search: "",
      eca_list_loading: false,

      form: {
        name: "",
        mobile: "",
        email: "",
        eca: [],
        eca_reference_number: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      country_list: "getCountryList",
      dialog: "university/addCaseDialog",
    }),

    show: {
      get() {
        return this.dialog.show;
      },
      set(value) {
        this.toggleDialog({ show: value });
      },
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    eca_search: _.debounce(function (query) {
      if (this.eca_list_loading) return;

      if (query && !this.form.eca.length) {
        this.getEcaList();
      }
    }, 1000),
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleDialog: "university/toggleAddCaseDialog",
    }),

    async openModal() {
      this.loading = true;

      this.selectedCountry = this.$_.find(this.country_list, ["iso2", "IN"]);

      this.loading = false;
    },

    modalClosed() {
      this.toggleDialog({ show: false });
      this.$refs.form.reset();
    },

    validateNegativeNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (value < 0) {
        this.form[field] = Number(0);
      } else this.form[field] = value;
    },

    validate_number() {
      if (this.form.mobile && this.selectedCountry)
        this.isMobileValid = this.$rules.phone_number_validator(
          this.form.mobile,
          this.selectedCountry.iso2
        );
    },

    getEcaList() {
      this.eca_list_loading = true;

      const onSuccess = (res) => {
        this.eca_list = res.data.data;
      };

      const onFinally = () => {
        this.eca_list_loading = false;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getCaseDetail() {
      const params = {
        case_id: this.dialog.id,
      };

      const onSuccess = (res) => {
        this.caseDetail = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = (res) => {
          console.log(res.data.message);
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = { ...this.form };

        return this.$request(
          this.$keys.POST,
          this.$urls.university.case.create,
          {
            data,
            onSuccess,
            onFinally,
          }
        );
      }
    },
  },
};
</script>
export default [
  {
    label: `Account Clearance Pending`,
    key: "account_clearance_pending",
    value: 0,
  },
  {
    label: `Account Clearance Rejected`,
    key: "account_clearance_rejected",
    value: 0,
  },
  {
    label: `Account Clearance Done`,
    key: "account_clearance_done",
    value: 0,
  },
];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"465px","content-class":"rounded-xl","retain-focus":false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.modalClosed.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.loading)?_c('Loading'):_c('v-card',{attrs:{"outlined":_vm.$vuetify.theme.dark,"rounded":"xl"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[(_vm.type === 'edit')?_c('div',{staticClass:"text-h6"},[_vm._v("Edit Payment Record")]):_c('div',{staticClass:"text-h6"},[_vm._v("Add Payment Record")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.modalClosed()}}},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-6"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[(!_vm.caseId)?_c('div',[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"clearable":"","auto-select-first":"","menu-props":{
              bottom: true,
              offsetY: true,
            },"append-icon":"mdi-chevron-down","rules":[_vm.$rules.required],"items":_vm.client_list,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","label":"Select Client"},on:{"change":_vm.getCaseList},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.user.mobile_display)+" | "+_vm._s(data.item.user.email)+" ")])],1)]}}],null,false,2875404708),model:{value:(_vm.form.client),callback:function ($$v) {_vm.$set(_vm.form, "client", $$v)},expression:"form.client"}}),_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"auto-select-first":"","clearable":"","menu-props":{
              bottom: true,
              offsetY: true,
            },"rules":[_vm.$rules.required],"append-icon":"mdi-chevron-down","items":_vm.case_list,"disabled":!_vm.form.client,"item-text":"case_id","return-object":"","flat":"","outlined":"","dense":"","label":"Select Case"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.case_id))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.service.name)+" | Pending Amount: "+_vm._s(data.item.pending_amount)+" ")])],1)]}}],null,false,1722417304),model:{value:(_vm.form.case),callback:function ($$v) {_vm.$set(_vm.form, "case", $$v)},expression:"form.case"}})],1):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"auto","offset-y":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"label":"Payment date","rules":[_vm.$rules.required],"height":"42px","solo":"","outlined":"","dense":"","flat":"","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.payment_date),callback:function ($$v) {_vm.$set(_vm.form, "payment_date", $$v)},expression:"form.payment_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","max":_vm.maxDate},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.form.payment_date),callback:function ($$v) {_vm.$set(_vm.form, "payment_date", $$v)},expression:"form.payment_date"}})],1),_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{
            bottom: true,
            offsetY: true,
          },"rules":[_vm.$rules.required],"append-icon":"mdi-chevron-down","items":_vm.payment_mode_list,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","label":"Select Payment Mode"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(data.item.name)+" - "+_vm._s(data.item.type)+" ")])],1)]}}]),model:{value:(_vm.form.mode),callback:function ($$v) {_vm.$set(_vm.form, "mode", $$v)},expression:"form.mode"}}),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":"","dense":"","hide-spin-buttons":"","type":"number","label":"Amount","rules":[
            _vm.$rules.required,
            _vm.$rules.validateNegativeNumber,
            _vm.validateMaxAmount ],"max":_vm.maxAmount},on:{"keydown":_vm.$rules.blockInvalidChar,"change":function($event){return _vm.validateNegativeNumber('amount')}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('v-textarea',{staticClass:"rounded-lg",attrs:{"rows":2,"type":"text","flat":"","outlined":"","dense":"","label":"Remarks"},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}}),(_vm.type === _vm.$keys.ADD)?_c('div',{staticClass:"mt-5"},[_c('div',[_vm._v("Attach Document (Optional)")]),_c('div',{staticClass:"file-input-container mt-2"},[_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"accept":"image/*, application/pdf","id":"file-input"},on:{"change":_vm.onFileChange}}),_c('label',{attrs:{"for":"file-input"}},[_c('div',{staticClass:"file-input d-flex align-center justify-center"},[(_vm.form.file)?_c('div',[(_vm.form.file.type.startsWith('image/'))?_c('v-img',{attrs:{"src":_vm.form.previewFile,"height":"120","width":"120"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true}],null,false,3042438443)}):_c('v-icon',{attrs:{"size":"50"}},[_vm._v("mdi-file")])],1):_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-upload")])],1)]),(_vm.form.file)?_c('v-icon',{staticClass:"file-remover",attrs:{"size":"20","color":"primary"},on:{"click":_vm.removeFile}},[_vm._v("mdi-close-circle")]):_vm._e()],1)]):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize px-8 my-2 rounded-lg",attrs:{"height":"40px","color":"primary","loading":_vm.btnLoading},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="mb-5 d-flex justify-space-between align-center">
      <!-- left -->
      <div class="d-flex align-center" style="gap: 20px">
        <!-- search -->
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          hide-details
          placeholder="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
          height="40px"
          class="rounded-lg"
        ></v-text-field>

        <v-menu
          v-model="filter.show"
          v-if="getAvailableFilters.length"
          :close-on-content-click="false"
          bottom
          offset-y
          nudge-bottom="10"
          origin="top left"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge color="primary" dot overlap :value="isFilterApplied">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-card width="340" :outlined="$vuetify.theme.dark">
            <v-card-title>Filters</v-card-title>

            <v-card-text>
              <!-- filter by date_type -->
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                v-model="filter.date_filter_type"
                :items="filter.dateTypes"
                item-value="key"
                item-text="value"
                outlined
                dense
                clearable
                placeholder="Filter by Date"
                class="rounded-lg"
              >
                <template v-slot:selection="{ item }">
                  <span> Filter by {{ item.value }} </span>
                </template>
              </v-select>

              <div v-if="filter.date_filter_type === 'custom'">
                <!-- start date -->
                <v-dialog v-model="filter.startDateDialog" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$utils.formatDate(filter.startDate, 'DD-MM-YYYY')"
                      label="Start Date"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="filter.startDate = ''"
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.startDate"
                    color="primary"
                    @input="filter.startDateDialog = false"
                    :max="maxDate"
                  ></v-date-picker>
                </v-dialog>

                <!-- end date -->
                <v-dialog v-model="filter.endDateDialog" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$utils.formatDate(filter.endDate, 'DD-MM-YYYY')"
                      label="End Date"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      :disabled="filter.startDate ? false : true"
                      @click:clear="filter.endDate = ''"
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.endDate"
                    color="primary"
                    @input="filter.endDateDialog = false"
                    :max="maxDate"
                    :min="minDate"
                  ></v-date-picker>
                </v-dialog>
              </div>

              <!-- Filter by assigned sales -->
              <v-autocomplete
                v-if="hasFilterAccess('filter_by_sales')"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                v-model="filter.executive"
                :items="executive_list"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                clearable
                height="40px"
                placeholder="Filter by Sales"
                class="rounded-lg"
              >
              </v-autocomplete>

              <!-- Filter by assigned operations -->
              <v-autocomplete
                v-if="hasFilterAccess('filter_by_operations')"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                v-model="filter.executive"
                :items="executive_list"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                clearable
                height="40px"
                placeholder="Filter by Operations"
                class="rounded-lg"
              >
              </v-autocomplete>

              <!-- Filter by university -->
              <v-autocomplete
                v-if="hasFilterAccess('filter_by_university')"
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="filter.university"
                :items="university_list"
                :search-input.sync="university_search"
                :loading="university_list_loading"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                clearable
                no-filter
                height="40px"
                placeholder="Filter by University"
                class="rounded-lg"
              >
                <template v-slot:no-data>
                  <div class="mx-3 my-1 text-caption">
                    <span v-if="university_list_loading">Searching...</span>
                    <span v-else> No data available </span>
                  </div>
                </template>
              </v-autocomplete>

              <!-- Filter by eca -->
              <v-autocomplete
                v-if="hasFilterAccess('filter_by_eca')"
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="filter.eca"
                :items="eca_list"
                :search-input.sync="eca_search"
                :loading="eca_list_loading"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                clearable
                no-filter
                height="40px"
                placeholder="Filter by ECA Body"
                class="rounded-lg"
              >
                <template v-slot:no-data>
                  <div class="mx-3 my-1 text-caption">
                    <span v-if="eca_list_loading">Searching...</span>
                    <span v-else> No data available </span>
                  </div>
                </template>
              </v-autocomplete>

              <!-- Filter by source -->
              <v-select
                v-if="hasFilterAccess('filter_by_source')"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                v-model="filter.source"
                :items="$keys.SOURCE_TYPES"
                item-text="label"
                item-value="key"
                placeholder="Filter by Source"
                clearable
                outlined
                dense
                return-object
                class="rounded-lg"
              >
                <template slot="item" slot-scope="data">
                  <v-icon>{{ data.item.icon }}</v-icon>
                  <span class="ml-3">{{ data.item.label }}</span>
                </template>

                <template slot="selection" slot-scope="data">
                  <v-icon>{{ data.item.icon }}</v-icon>
                  <span class="ml-3">{{ data.item.label }}</span>
                </template>
              </v-select>

              <!-- Filter by service_type -->
              <v-select
                v-if="hasFilterAccess('filter_by_service_type')"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                v-model="filter.service_type"
                :items="$keys.SERVICE_TYPES"
                item-text="label"
                item-value="key"
                placeholder="Filter by Service Type"
                clearable
                outlined
                dense
                return-object
                class="rounded-lg"
              >
              </v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn class="px-4" text @click="filter.show = false">
                Cancel
              </v-btn>
              <v-btn class="px-4" color="primary" text @click="applyFilter">
                Apply
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-slide-group>
          <v-slide-x-transition
            v-if="
              filters.date_filter_type && filters.date_filter_type !== 'custom'
            "
          >
            <v-slide-item>
              <v-chip class="mx-2 px-4">
                Date: {{ dateFilterType.value }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition
            v-if="filters.date_filter_type == 'custom' && filters.start_date"
          >
            <v-slide-item>
              <v-chip class="mx-2 px-4">
                Start Date:
                {{ $utils.formatDate(filters.start_date, "DD-MM-YYYY") }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition
            v-if="filters.date_filter_type == 'custom' && filters.end_date"
          >
            <v-slide-item>
              <v-chip class="mx-2 px-4">
                End Date:
                {{ $utils.formatDate(filters.end_date, "DD-MM-YYYY") }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition>
            <v-slide-item v-if="filters.executive">
              <v-chip class="mx-2 px-4">
                Sales: {{ filters.executive.name }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition>
            <v-slide-item v-if="filters.university">
              <v-chip class="mx-2 px-4">
                University: {{ filters.university.name }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition>
            <v-slide-item v-if="filters.eca">
              <v-chip class="mx-2 px-4"> ECA: {{ filters.eca.name }} </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition>
            <v-slide-item v-if="filters.source">
              <v-chip class="mx-2 px-4">
                Source: {{ filters.source.label }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>

          <v-slide-x-transition>
            <v-slide-item v-if="filters.service_type">
              <v-chip class="mx-2 px-4">
                Service Type: {{ filters.service_type.label }}
              </v-chip>
            </v-slide-item>
          </v-slide-x-transition>
        </v-slide-group>

        <v-btn icon @click="clearFilter" v-if="isFilterApplied">
          <v-icon>mdi-filter-variant-remove</v-icon>
        </v-btn>
      </div>

      <!-- right -->
      <div>
        <!-- add case btn -->
        <v-btn
          @click="caseAdd"
          class="text-capitalize rounded-lg"
          color="primary"
          height="40px"
          outlined
          v-if="
            [
              $keys.SALES_EXECUTIVE,
              $keys.SALES_HEAD,
              $keys.UNIVERSITY,
              $keys.SUPER_ADMIN,
            ].includes(user.user_type)
          "
        >
          <v-icon size="16" left>mdi-plus</v-icon>Add Case
        </v-btn>
      </div>
    </div>

    <!-- tabs -->
    <v-sheet elevation="3" class="rounded-b-0">
      <v-tabs
        height="125px"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="index + item.key"
          class="tab-width"
        >
          <div>
            <div class="tab-total">
              {{ item.value }}
            </div>
            <div class="tab-name">
              {{ item.label }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-sheet>

    <v-divider />

    <!-- sales table -->
    <admin-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.SUPER_ADMIN].includes(user.user_type)"
    />

    <!-- sales table -->
    <sales-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.SALES_HEAD, $keys.SALES_EXECUTIVE].includes(user.user_type)"
    />

    <!-- operations table -->
    <operation-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="
        [$keys.OPERATIONS_HEAD, $keys.OPERATIONS_EXECUTIVE].includes(
          user.user_type
        )
      "
    />

    <!-- accounts table -->
    <accounts-data-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.ACCOUNTS].includes(user.user_type)"
    />

    <!-- university table -->
    <university-data-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.UNIVERSITY].includes(user.user_type)"
    />

    <!-- eca table -->
    <eca-data-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.ECA_BODY].includes(user.user_type)"
    />

    <!-- main -->
    <router-view />

    <!-- dialogs -->
    <AddCaseDialog @submitted="reloadData" />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import {
  salesTabs,
  operationsTabs,
  accountsTabs,
  universityTabs,
  ecaTabs,
  adminTabs,
} from "@/tabs/caseManagement";

import adminTable from "./adminTable";
import salesTable from "./salesTable.vue";
import OperationTable from "./operationTable.vue";

import AccountsDataTable from "@/components/CaseManagement/AccountsDataTable";
import UniversityDataTable from "@/components/CaseManagement/University/DataTable";
import EcaDataTable from "@/components/CaseManagement/EcaDataTable";
import AddCaseDialog from "@/components/CaseManagement/University/AddCaseDialog";

export default {
  components: {
    adminTable,
    salesTable,
    OperationTable,
    AccountsDataTable,
    UniversityDataTable,
    EcaDataTable,
    AddCaseDialog,
  },
  data() {
    return {
      dateMenu: false,
      executive_list: [],

      university_list: [],
      university_search: "",
      university_list_loading: false,

      eca_list: [],
      eca_search: "",
      eca_list_loading: false,

      filter: {
        show: false,
        executive: null,
        university: null,
        eca: null,
        service_type: null,
        source: null,
        date_filter_type: "",
        dateTypes: [
          { key: "today", value: "Today" },
          { key: "yesterday", value: "Yesterday" },
          { key: "this_week", value: "This Week" },
          { key: "this_month", value: "This Month" },
          { key: "this_year", value: "This Year" },
          { key: "custom", value: "Custom Date" },
        ],
        startDate: "",
        startDateDialog: false,
        endDate: "",
        endDateDialog: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      filters: "caseManagement/filters",
      getSearch: "caseManagement/search",
      getActiveTab: "caseManagement/activeTab",
      serviceTypes: "getServiceTypes",
      serviceTypeSwitch: "topNav/serviceTypeSwitch",
    }),

    activeTab: {
      get() {
        return this.getActiveTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    selectedServiceType() {
      if (this.serviceTypes.length) {
        return this.serviceTypes[this.serviceTypeSwitch.selected].key;
      }
      return this.$keys.EDUCATIONAL_DOCUMENTATION;
    },

    tabs() {
      let _tabs = [];
      switch (this.user.user_type) {
        case this.$keys.SALES_HEAD:
        case this.$keys.SALES_EXECUTIVE:
          _tabs = salesTabs;
          break;

        case this.$keys.OPERATIONS_HEAD:
        case this.$keys.OPERATIONS_EXECUTIVE: {
          _tabs = operationsTabs[this.selectedServiceType];
          break;
        }

        case this.$keys.ACCOUNTS:
          _tabs = accountsTabs;
          break;

        case this.$keys.UNIVERSITY:
          _tabs = universityTabs;
          break;

        case this.$keys.ECA_BODY:
          _tabs = ecaTabs;
          break;

        case this.$keys.SUPER_ADMIN:
          _tabs = adminTabs[this.selectedServiceType];
          break;

        default:
          _tabs = [];
      }

      return _.filter(_tabs, (tab) => {
        if (
          !(
            ["open", "assigned_to_sales", "assigned_to_operations"].includes(
              tab.key
            ) &&
            ["sales_executive", "operations_executive"].includes(
              this.user.user_type
            )
          )
        )
          return tab;
      });
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    getAvailableFilters() {
      const filters = [];

      if ([this.$keys.SALES_HEAD].includes(this.user.user_type)) {
        filters.push("filter_by_sales");
      }

      if (
        [this.$keys.OPERATIONS_HEAD, this.$keys.ACCOUNTS].includes(
          this.user.user_type
        )
      ) {
        filters.push("filter_by_operations");
      }

      if (
        [
          this.$keys.SALES_HEAD,
          this.$keys.SALES_EXECUTIVE,
          this.$keys.ACCOUNTS,
        ].includes(this.user.user_type)
      ) {
        filters.push("filter_by_service_type");
      }

      if (
        [
          this.$keys.SUPER_ADMIN,
          this.$keys.SALES_HEAD,
          this.$keys.SALES_EXECUTIVE,
          this.$keys.OPERATIONS_HEAD,
          this.$keys.OPERATIONS_EXECUTIVE,
        ].includes(this.user.user_type)
      ) {
        filters.push("filter_by_source");
        filters.push("filter_by_university");
        filters.push("filter_by_eca");
      }

      return filters;
    },

    isFilterApplied() {
      const filters = this.filters;
      return (
        filters.executive ||
        filters.university ||
        filters.eca ||
        filters.date_filter_type ||
        filters.start_date ||
        filters.end_date ||
        filters.source ||
        filters.service_type
      );
    },

    dateFilterType() {
      return this.$_.find(this.filter.dateTypes, {
        key: this.filters.date_filter_type,
      });
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    minDate() {
      const startDate = this.$moment(this.filter.startDate);
      return startDate.add(1, "days").format("YYYY-MM-DD");
    },
  },

  watch: {
    selectedServiceType() {
      this.activeTab = 0;
    },

    university_search: _.debounce(function (query) {
      if (this.university_list_loading) return;

      if (query && !this.university) {
        this.getUniversityList();
      }
    }, 1000),

    eca_search: _.debounce(function (query) {
      if (this.eca_list_loading) return;

      if (query && !this.eca) {
        this.getEcaList();
      }
    }, 1000),

    "filter.date_filter_type"() {
      this.filter.startDate = "";
      this.filter.endDate = "";
    },

    "filter.startDate"() {
      this.filter.endDate = "";
    },

    "filter.show"(value) {
      if (value) {
        this.filter.executive = this.filters.executive;
        this.filter.university = this.filters.university;
        this.filter.eca = this.filters.eca;
        this.filter.date_filter_type = this.filters.date_filter_type;
        this.filter.startDate = this.filters.start_date;
        this.filter.endDate = this.filters.end_date;
        this.filter.source = this.filters.source;
        this.filter.service_type = this.filters.service_type;
      }
    },
  },

  created() {
    if (this.tabs) {
      this.setActiveTab({
        index: this.activeTab,
        key: this.tabs[this.activeTab].key,
      });
    }

    this.getCountList();
  },

  mounted() {
    this.getExecutiveList();

    if (
      [
        this.$keys.OPERATIONS_HEAD,
        this.$keys.OPERATIONS_EXECUTIVE,
        this.$keys.SUPER_ADMIN,
      ].includes(this.user.user_type)
    ) {
      this.toggleServiceTypeSwitch({ show: true });
    }

    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.toggleServiceTypeSwitch({ show: false });
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setSearch: "caseManagement/setSearch",
      updateFilters: "caseManagement/updateFilters",
      setActiveTab: "caseManagement/setActiveTab",
      toggleAddCaseDialog: "university/toggleAddCaseDialog",
      toggleServiceTypeSwitch: "topNav/toggleServiceTypeSwitch",
    }),

    navClickHandler(navKey) {
      if (navKey === "case_management") {
        this.reloadData();
        this.$refs.dataTable.updateRoute();
      }
    },

    reloadData() {
      this.getCountList();
      this.$refs.dataTable.getCaseList();
    },

    applyFilter() {
      this.filter.show = false;
      this.updateFilters({
        executive: this.filter.executive,
        university: this.filter.university,
        eca: this.filter.eca,
        date_filter_type: this.filter.date_filter_type,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        source: this.filter.source,
        service_type: this.filter.service_type,
      });
    },

    clearFilter() {
      this.updateFilters({
        executive: null,
        university: null,
        eca: null,
        date_filter_type: "",
        start_date: "",
        end_date: "",
        source: null,
        service_type: null,
      });
    },

    hasFilterAccess(filter_key) {
      return this.getAvailableFilters.includes(filter_key);
    },

    caseAdd() {
      if ([this.$keys.UNIVERSITY].includes(this.user.user_type)) {
        this.toggleAddCaseDialog({ show: true });
      } else {
        this.$router.push({ name: "caseAdd" });
      }
    },

    getCountList() {
      const onSuccess = (res) => {
        this.tabs.forEach((tab) => {
          tab.value = res.data.data[tab.key];
        });
        this.$forceCompute("tabs");
      };

      const filters = this.filters;
      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        search: this.search,
        date_filter_type: filters.date_filter_type,
        source: filters.source ? filters.source.key : "",
        service_type: filters.service_type ? filters.service_type.key : "",
        executive_id: filters.executive ? filters.executive.id : null,
        university_id: filters.university ? filters.university.id : null,
        eca_body_id: filters.eca ? filters.eca.id : null,
        start_date: filters.start_date,
        end_date: filters.end_date,
      };

      if (
        [
          this.$keys.OPERATIONS_HEAD,
          this.$keys.OPERATIONS_EXECUTIVE,
          this.$keys.SUPER_ADMIN,
        ].includes(this.user.user_type)
      ) {
        params["service_type"] = this.selectedServiceType;
      }

      return this.$request(this.$keys.GET, this.$urls.case.count, {
        params,
        onSuccess,
      });
    },

    getExecutiveList() {
      const params = {
        apply_pagination: false,
      };

      if ([this.$keys.SALES_HEAD].includes(this.user.user_type)) {
        params["list_type"] = "assign_sales";
      }

      if (
        [this.$keys.OPERATIONS_HEAD, this.$keys.ACCOUNTS].includes(
          this.user.user_type
        )
      ) {
        params["list_type"] = "assign_operations";
      }

      const successHandler = (res) => {
        this.executive_list = res.data.data;
      };

      this.$request(this.$keys.GET, this.$urls.team.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getUniversityList() {
      this.university_list_loading = true;

      const onSuccess = (res) => {
        this.university_list = res.data.data;
      };

      const onFinally = () => {
        this.university_list_loading = false;
      };

      const params = {
        search: this.university_search,
      };

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getEcaList() {
      this.eca_list_loading = true;

      const onSuccess = (res) => {
        this.eca_list = res.data.data;
      };

      const onFinally = () => {
        this.eca_list_loading = false;
      };

      const params = {
        search: this.eca_search,
      };

      return this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>
<style scoped>
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
</style>

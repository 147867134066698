<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="680px"
      content-class="rounded-xl"
    >
      <v-card :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div class="card-title">Status History</div>
          <v-btn icon>
            <v-icon @click="modalClosed" size="26">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-simple-table fixed-header height="280">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">ECA Body</th>
                  <th class="text-center">Sent</th>
                  <th class="text-center">Viewed</th>
                  <th class="text-center">Downloaded</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="`item-${index}`">
                  <td>{{ item.eca_body }}</td>
                  <td>
                    <div v-if="item.sent">
                      {{ $moment(item.sent).format("h:mm a, D MMM YYYY") }}
                    </div>
                    <v-icon v-else>mdi-minus</v-icon>
                  </td>
                  <td>
                    <div v-if="item.viewed">
                      {{ $moment(item.viewed).format("h:mm a, D MMM YYYY") }}
                    </div>
                    <v-icon v-else>mdi-minus</v-icon>
                  </td>
                  <td>
                    <div v-if="item.downloaded">
                      {{
                        $moment(item.downloaded).format("h:mm a, D MMM YYYY")
                      }}
                    </div>
                    <v-icon v-else>mdi-minus</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  computed: {
    ...mapGetters({
      dialog: "caseManagement/ecaStatusHistoryDialog",
    }),
    show: {
      get() {
        return this.dialog.show;
      },
      set() {},
    },
    items() {
      const group = this.$_(this.dialog.items)
        .groupBy((item) => item.eca.name)
        .value();

      const items = [];
      Object.entries(group).forEach(([key, values]) => {
        const item = { eca_body: key, sent: "", viewed: "", downloaded: "" };

        values.forEach((value) => {
          item[value.status] = value.created;
        });

        items.push(item);
      });

      return items;
    },
  },
  methods: {
    ...mapActions({
      toggleDialog: "caseManagement/toggleEcaStatusHistoryDialog",
    }),

    openModal() {},

    modalClosed() {
      this.toggleDialog({ show: false, items: [] });
    },
  },
};
</script>
<style scoped>
.card-title {
  font-size: 1.1rem;
}
</style>
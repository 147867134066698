<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="540px"
    content-class="rounded-xl"
  >
    <Loading v-if="loading" />
    <v-card :outlined="$vuetify.theme.dark" rounded="xl" v-else>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Add Tracking Info.</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text v-if="item" class="mt-5">
        <v-form lazy-validation ref="form">
          <div>
            <label class="text-body-2 font-weight-medium"> Shipment by </label>
            <v-radio-group row v-model="form.shipment_by">
              <v-radio
                v-for="shipment_by in shipment_by_choices"
                :key="shipment_by.key"
                :label="shipment_by.value"
                :value="shipment_by.key"
              ></v-radio>
            </v-radio-group>
          </div>

          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
            offset-y
            nudge-top="20"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-text-field
                v-model="form.dispatch_date"
                label="Dispatch Date"
                v-bind="attrs"
                height="42px"
                :rules="[$rules.required]"
                outlined
                dense
                flat
                append-icon="mdi-calendar"
                v-on="on"
                readonly
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="form.dispatch_date"
              no-title
              :max="maxDate"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-select
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="form.dispatch_via"
            :items="shipping_service_list"
            :rules="[$rules.required]"
            item-text="name"
            item-value="id"
            flat
            outlined
            dense
            label="Dispatch Via"
            class="rounded-lg"
          ></v-select>

          <v-text-field
            v-model="form.tracking_id"
            label="Tracking Id"
            :rules="[$rules.required]"
            height="42px"
            type="text"
            outlined
            dense
            class="rounded-lg"
          ></v-text-field>

          <v-textarea
            :rows="3"
            v-model="form.remarks"
            label="Remarks"
            type="text"
            outlined
            dense
            class="rounded-lg"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          :loading="btnLoading"
          color="primary"
          class="text-capitalize px-8 my-2"
          height="40px"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      shipping_service_list: [],
      dateMenu: false,

      form: {
        shipment_by: "fe_ir",
        dispatch_date: "",
        dispatch_via: null,
        tracking_id: "",
        remarks: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      addTrackingInfo: "dialogs/addTrackingInfoDialog",
    }),

    show: {
      get() {
        return this.addTrackingInfo.show;
      },
      set(value) {
        this.toggleDialog({ show: value });
      },
    },

    item() {
      return this.addTrackingInfo.item;
    },

    shipment_by_choices() {
      const choices = [];
      const fe_ir = { key: "fe_ir", value: "Fe/IR" };
      const university = { key: "university", value: "University" };
      const vendor = { key: "vendor", value: "Vendor" };

      if (this.item.service.type === this.$keys.EDUCATIONAL_DOCUMENTATION) {
        if (this.user.user_type === this.$keys.UNIVERSITY) {
          choices.push(university);
        } else {
          choices.push(fe_ir);
          choices.push(university);
        }
      }

      if (
        [this.$keys.APOSTILLE_TRANSLATIONS, this.$keys.CERTIFICATIONS].includes(
          this.item.service.type
        )
      ) {
        choices.push(vendor);
      }
      return choices;
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  methods: {
    ...mapActions({
      toggleDialog: "dialogs/toggleAddTrackingInfoDialog",
      showSnackbar: "app/showSnackbar",
    }),

    async openModal() {
      this.loading = true;
      await this.$utils.sleep(0.5);
      await this.getShippingServiceList();
      await this.getTrackingInfo();
      this.loading = false;
    },

    async modalClosed() {
      this.loading = true;
      await this.$utils.sleep(0.5);

      this.toggleDialog({ show: false, item: null });
      this.form = {
        shipment_by: "",
        dispatch_date: "",
        dispatch_via: null,
        tracking_id: "",
        remarks: "",
      };

      this.loading = false;
    },

    getTrackingInfo() {
      const onSuccess = (res) => {
        const data = res.data.data;
        this.form = {
          shipment_by: data.shipment_by,
          dispatch_date: data.dispatch_date,
          dispatch_via: data.dispatch_via,
          tracking_id: data.tracking_id,
          remarks: data.remarks,
        };
      };

      const params = {
        case_id: this.item.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.trackingInfo, {
        params,
        onSuccess,
      });
    },

    getShippingServiceList() {
      const onSuccess = (res) => {
        this.shipping_service_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.master.shippingService.list,
        {
          params,
          onSuccess,
        }
      );
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = () => {
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const params = {
          case_id: this.item.id,
        };

        const data = {
          shipment_by: this.form.shipment_by,
          dispatch_date: this.form.dispatch_date,
          dispatch_via: this.form.dispatch_via,
          tracking_id: this.form.tracking_id,
          remarks: this.form.remarks,
        };

        return this.$request(this.$keys.POST, this.$urls.case.trackingInfo, {
          params,
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>

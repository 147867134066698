export default [
  {
    sortable: false,
    text: "Date",
    value: "date",
  },
  {
    sortable: false,
    text: "Student Name",
    value: "client",
  },
  {
    sortable: false,
    text: "ECA Reference No.",
    value: "eca_reference_number",
  },
  {
    sortable: false,
    text: "University City & State",
    value: "university",
  },
  {
    sortable: false,
    text: "Action",
    value: "action",
  },
];

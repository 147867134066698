<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="title-name">Select Quotation Type</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-radio-group v-model="form.quotation_type">
            <v-radio
              v-for="item in $keys.QUOTATION_TYPES"
              :key="item.key"
              :label="item.label"
              :rules="[$rules.required]"
              :value="item.key"
            ></v-radio>
          </v-radio-group>
          <v-divider></v-divider>
          <v-checkbox
            v-model="form.is_combined"
            label="Send Combined Quotation"
            hide-details
          ></v-checkbox>
          <div class="mt-2" v-if="form.is_combined">
            <div v-if="case_list.length">
              <b>Select Cases</b>
              <v-checkbox
                v-for="item in case_list"
                :key="item.id"
                v-model="selectedCases"
                :value="item.id"
                hide-details
                dense
              >
                <template v-slot:label>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.case_id }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.service.name }}
                      <span class="text-caption">
                        [Amount: {{ item.total_amount }}]
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-badge inline :content="item.status_display"></v-badge>
                  </v-list-item-icon>
                </template>
              </v-checkbox>
            </div>
            <div v-else>No case found</div>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          class="text-capitalize px-8 my-2 rounded-lg"
          height="40px"
          color="primary"
          :loading="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      selectedCases: [],
      case_list: [],
      form: {
        quotation_type: "bifurcated",
        is_combined: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      sendQuotationDialog: "dialogs/sendQuotationDialog",
    }),
    show: {
      get() {
        return this.sendQuotationDialog.show;
      },
      set(value) {
        this.toggleSendQuotationDialog({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
    "form.is_combined"(value) {
      if (!value) {
        this.selectedCases = [];
      }
    },
  },
  methods: {
    ...mapActions({
      toggleSendQuotationDialog: "dialogs/toggleSendQuotationDialog",
      showSnackbar: "app/showSnackbar",
    }),

    openModal() {
      this.getCaseList();
    },

    modalClosed() {
      this.selectedCases = [];
      this.case_list = [];
      this.form = {
        quotation_type: "bifurcated",
        is_combined: false,
      };
      this.toggleSendQuotationDialog({ show: false });
    },

    getCaseList() {
      const onSuccess = (res) => {
        this.case_list = this.$_.filter(res.data.data, (item) => {
          if (item.id != this.sendQuotationDialog.caseId) return item;
        });
      };

      let params = {};
      params["client_id"] = this.sendQuotationDialog.clientId;
      params["list_type"] = "send_quotation";

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const params = new URLSearchParams();
        this.selectedCases.forEach((item) => params.append("case_id", item));

        const data = {
          case: this.sendQuotationDialog.caseId,
          quotation_type: this.form.quotation_type,
        };

        if (this.form.is_combined && this.selectedCases.length) {
          data["is_combined"] = this.form.is_combined;
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });

          this.$emit("submitted");
          this.modalClosed();
        };

        const onFinally = () => {
          this.loading = false;
        };

        return this.$request(this.$keys.POST, this.$urls.case.sendQuotation, {
          params,
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>

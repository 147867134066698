<template>
  <v-sheet outlined v-if="item">
    <v-zoomer
      ref="zoomer"
      :style="{ width: width, height: height }"
      :aspect-ratio="imageAspectRatio"
      :zoomed.sync="zoomed"
      :max-scale="10"
      :doubleClickToZoom="false"
      :zooming-elastic="true"
      :class="zoomed ? 'cursor-zoom-out' : 'cursor-zoom-in'"
      class="my-0"
    >
      <img
        @dblclick="onImageClick"
        style="object-fit: contain; width: 100%; height: 100%"
        :src="item.url"
        @load="onImageLoad"
        class="transition"
        :style="{ transform: `rotate(${deg}deg)` }"
      />
    </v-zoomer>
    <v-divider />
    <v-toolbar flat dense>
      <v-toolbar-title>{{ item.name }}</v-toolbar-title>
      <v-spacer />
      <v-btn width="35" height="35" icon @click="$refs.zoomer.zoomIn()">
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="$refs.zoomer.zoomOut()">
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="rotateLeft">
        <v-icon>mdi-rotate-left</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="rotateRight">
        <v-icon>mdi-rotate-right</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon @click="download(item.url, item.name)">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn width="35" height="35" icon :href="item.url" target="_blank">
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-toolbar>
  </v-sheet>
</template>
<script>
import { saveAs } from "file-saver";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      deg: 0,
      zoomed: false,
      imageAspectRatio: 1,
    };
  },
  unmounted() {
    this.deg = 0;
    this.zoomed = false;
    this.imageAspectRatio = 1;
    this.$refs.zoomer.reset();
  },
  methods: {
    onImageLoad(e) {
      const img = e.target;
      this.imageAspectRatio = img.naturalWidth / img.naturalHeight;
    },
    onImageClick() {
      this.zoomed ? this.$refs.zoomer.zoomOut() : this.$refs.zoomer.zoomIn();
    },
    rotateLeft() {
      this.deg -= 90;
    },
    rotateRight() {
      this.deg += 90;
    },
    download(fileUrl, fileName) {
      saveAs(fileUrl, fileName);
    },
  },
};
</script>
<style scoped>
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.transition {
  transition: transform 0.5s ease-in-out;
}
</style>
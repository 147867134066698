<template>
  <div>
    <v-dialog
      persistent
      v-model="show"
      max-width="400px"
      content-class="rounded-xl"
    >
      <v-card :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-text class="pt-4 pb-8">
          <div v-if="loading" class="d-flex flex-column align-center mt-6">
            <v-progress-circular
              size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <div class="mt-10 font-weight-bold">{{ loadingMessage }}</div>
          </div>
          <div v-if="redirected" class="d-flex justify-center mt-6">
            <v-btn
              outlined
              @click="onModalClosed"
              class="text-capitalize px-4 rounded-lg mr-4"
              height="36px"
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="checkStatus"
              class="text-capitalize px-4 rounded-lg"
              height="36px"
              color="primary"
            >
              Check payment status
            </v-btn>
          </div>
          <div v-if="success" class="d-flex flex-column align-center mt-6">
            <v-img src="@/assets/images/success.png" />
            <div class="mt-8 font-weight-bold">
              Payment Successfully Received
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      loadingMessage: "",
      sessionId: "",
      redirected: false,
      success: false,
    };
  },

  computed: {
    ...mapGetters({
      dialog: "dialogs/stripeCheckoutDialog",
    }),

    show: {
      get() {
        return this.dialog.show;
      },
      set() {
        this.onModalClosed();
      },
    },

    items() {
      return this.dialog.items;
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.onModalOpen();
      } else this.onModalClosed();
    },

    async success(value) {
      if (value) {
        this.loading = false;
        this.redirected = false;
        this.loadingMessage = "";
        await this.$utils.sleep(2);
        this.onModalClosed();
      }
    },
  },

  methods: {
    ...mapActions({
      toggleStripeCheckoutDialog: "dialogs/toggleStripeCheckoutDialog",
    }),

    onModalOpen() {
      this.processPayment();
    },

    onModalClosed() {
      this.toggleStripeCheckoutDialog({
        show: false,
      });
      this.loading = false;
      this.loadingMessage = "";
      this.sessionId = "";
      this.redirected = false;
      this.success = false;
      this.$emit("closed");
    },

    processPayment() {
      this.loading = true;
      this.loadingMessage = "Initializing Checkout...";

      const data = {
        cases: this.items.map((item) => item.id),
      };

      const onSuccess = (res) => {
        this.loadingMessage = "Redirecting to checkout page...";
        this.sessionId = res.data.data.session_id;
        this.$refs.checkoutRef.redirectToCheckout();
        this.redirected = true;
      };

      const onFailure = () => {
        this.loadingMessage = "Initialization Failed";
      };

      return this.$request(
        this.$keys.POST,
        this.$urls.university.case.initiateStripeCheckout,
        {
          data,
          onSuccess,
          onFailure,
        }
      );
    },

    checkStatus() {
      const onSuccess = (res) => {
        this.success = res.data.data.is_success;
      };

      const params = {
        session_id: this.sessionId,
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.master.stripeCheckoutStatus,
        {
          params,
          onSuccess,
        }
      );
    },
  },
};
</script>
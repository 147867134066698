<template>
  <div>
    <!-- data table -->
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
      @dblclick:row="onItemDoubleClick"
      @contextmenu:row="onRightClick"
    >
      <template v-slot:[`item.case_id`]="{ item }">
        <div class="mb-1">
          <v-icon size="20" :color="$utils.getSourceColor(item.source)">
            {{ $utils.getSourceIcon(item.source) }}
          </v-icon>
        </div>
        {{ item.case_id }}
        <v-chip
          small
          pill
          class="mt-1"
          v-if="item.delayed_days > 0"
          color="red"
          outlined
        >
          Delayed by {{ item.delayed_days }} days
        </v-chip>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <div v-if="item.converted_dt">
          {{ $utils.formatDate(item.converted_dt) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status_datetime`]="{ item }">
        <div v-if="item.status_datetime">
          {{ $utils.formatDate(item.status_datetime) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div>{{ item.client.name }}</div>
        <div>{{ item.client.user.email }}</div>
        <div>{{ item.client.user.mobile_display }}</div>
      </template>

      <template v-slot:[`item.tat`]="{ item }">
        <div v-if="item.tat_status === 'not_started'">
          TAT not started
          <div class="red--text">{{ item.tat_reason }}</div>
        </div>
        <div v-else-if="item.tat_status === 'stopped'">
          TAT stopped
          <div class="red--text">{{ item.tat_reason }}</div>
        </div>
        <div v-else>
          <div>
            <span class="text-body-2">Client TAT: </span>
            <span class="font-weight-medium">
              {{ item.client_tat_days }}
              {{ item.client_tat_days > 1 ? " days" : " day" }}
            </span>
          </div>
          <div class="">
            <span class="text-body-2">Internal TAT: </span>
            <span class="font-weight-medium">
              {{ item.internal_tat_days }}
              {{ item.internal_tat_days > 1 ? " days" : " day" }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.total_amount`]="{ item }">
        <div class="d-flex align-center justify-center">
          <span class="mr-2">₹{{ item.total_amount }}</span>
          <v-tooltip bottom v-if="item.total_amount">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="pointer">
                mdi-information
              </v-icon>
            </template>
            <div>
              <div v-if="item.total_university_cost">
                University Cost: ₹{{ item.total_university_cost }}
              </div>
              <div>Processing Cost: ₹{{ item.total_processing_cost }}</div>
              <div>Shipping Cost: ₹{{ item.shipping_cost }}</div>
              <div>GST Amount: ₹{{ item.gst_amount }}</div>
              <div>Discount Amount: ₹{{ item.discount_amount }}</div>
            </div>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.university`]="{ item }">
        <div v-if="item.university">
          {{ item.university.name }}
          <div v-if="item.university.city">
            {{ item.university.city.name
            }}<span v-if="item.university.city.state"
              >, {{ item.university.city.state.name }}</span
            >
          </div>
          <div
            class="pointer primary--text"
            @click="assignUniversity(item.id)"
            v-if="item.actions.includes($keys.ASSIGN_UNIVERSITY)"
          >
            <span class="executive">Assign University</span>
          </div>
          <div
            class="pointer primary--text"
            @click="unassignUniversity(item.id)"
            v-if="item.actions.includes($keys.UNASSIGN_UNIVERSITY)"
          >
            <span class="executive">Unassign University</span>
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.eca`]="{ item }">
        <div v-if="item.eca.length" class="text-left">
          <li v-for="eca in item.eca" :key="`eca-${eca.id}`">
            {{ eca.name }}
          </li>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.eca_reference_number`]="{ item }">
        <div class="d-flex align-center justify-center">
          {{ item.eca_reference_number }}
          <div style="max-width: 100px">
            <v-edit-dialog
              @save="onSave('eca_reference_number', item)"
              @open="
                editDialog.eca_reference_number = item.eca_reference_number
              "
              @close="editDialog.eca_reference_number = ''"
              large
              transition="slide-y-transition"
            >
              <v-btn icon x-small color="primary" class="ml-1">
                <v-icon x-small>mdi-pencil-outline</v-icon>
              </v-btn>
              <template v-slot:input>
                <div class="my-4">ECA Reference Number</div>
                <v-text-field
                  v-model="editDialog.eca_reference_number"
                  label="Enter ECA Reference Number"
                  type="text"
                  outlined
                  dense
                  class="rounded-lg"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </div>
        </div>
      </template>

      <template v-slot:[`item.documents`]="{ item }">
        <div v-if="item.required_documents.length">
          <div v-for="(list, index) in item.required_documents" :key="index">
            <div>
              {{ list.title }} -
              <span v-if="list.status == 'pending'" class="blue--text bold">
                {{ list.status_display }}</span
              >
              <span v-if="list.status == 'uploaded'" class="orange--text bold">
                {{ list.status_display }}</span
              >
              <span v-if="list.status == 'verified'" class="green--text bold">
                {{ list.status_display }}</span
              >
              <span v-if="list.status == 'rejected'" class="red--text bold">
                {{ list.status_display }}</span
              >
              <span v-if="list.status == 'downloaded'" class="gray--text bold">
                {{ list.status_display }}</span
              >
              <span v-if="list.status == 'removed'" class="red--text bold">
                {{ list.status_display }}</span
              >
            </div>
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        {{ item.service.name }}
      </template>

      <template v-slot:[`item.follow_up_date`]="{ item }">
        <div v-if="item.follow_up">
          {{ $utils.formatDate(item.follow_up.follow_up_date) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.follow_up_reason`]="{ item }">
        <div v-if="item.follow_up">
          {{ item.follow_up.reason }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.close_reason`]="{ item }">
        <div v-if="item.close_reason">
          {{ item.close_reason }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.on_hold_reason`]="{ item }">
        <div v-if="item.follow_up">
          {{ item.follow_up.reason }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 'closed'">
          <div
            class="pointer primary--text executive"
            @click="openEcaStatusHistoryDialog(item)"
          >
            View Status History
          </div>
        </div>
        <div v-else-if="item.status">
          <v-chip small outlined color="primary">
            {{ item.status_display }}
          </v-chip>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.assigned_sales`]="{ item }">
        <div v-if="item.sales">
          {{ item.sales.name }}
        </div>

        <!-- assign sales -->
        <div
          class="pointer primary--text"
          @click="assignSales(item.id)"
          v-if="item.actions.includes($keys.ASSIGN_SALES)"
        >
          <v-icon color="primary" size="14"> mdi-account-plus </v-icon>
          <span class="executive ml-1">Assign Sales </span>
        </div>

        <!-- change sales -->
        <div
          class="pointer d-flex justify-center align-end primary--text mb-1"
          @click="assignSales(item.id)"
          v-if="item.actions.includes($keys.CHANGE_SALES)"
        >
          <v-icon color="primary" size="14"> mdi-account-edit </v-icon>
          <span class="executive ml-1">Change Sales </span>
        </div>

        <!-- unassign me -->
        <div
          class="pointer d-flex justify-center align-end primary--text"
          @click="unAssignSales(item.id)"
          v-if="item.actions.includes($keys.UNASSIGN_ME)"
        >
          <v-icon color="primary" size="14"> mdi-account-minus </v-icon>
          <span class="executive ml-1">Unassign me</span>
        </div>

        <!-- unassign sales -->
        <div
          class="pointer d-flex justify-center align-end primary--text"
          @click="unAssignSales(item.id)"
          v-if="item.actions.includes($keys.UNASSIGN_SALES)"
        >
          <v-icon color="primary" size="14"> mdi-account-minus </v-icon>
          <span class="executive ml-1">Unassign Sales</span>
        </div>
      </template>

      <template v-slot:[`item.assigned_operations`]="{ item }">
        <div v-if="item.operations">
          <div class="mb-1">
            {{ item.operations.name }}
          </div>

          <div
            v-if="
              item.actions.includes($keys.CHANGE_OPERATIONS) ||
              item.actions.includes($keys.UNASSIGN_ME) ||
              item.actions.includes($keys.UNASSIGN_OPERATIONS)
            "
          >
            <div
              class="pointer d-flex justify-center primary--text mb-1"
              @click="
                assignOperations(
                  item.id,
                  item.university ? item.university.id : null
                )
              "
              v-if="item.actions.includes($keys.CHANGE_OPERATIONS)"
            >
              <v-icon color="primary" size="14"> mdi-account-edit </v-icon>
              <span class="executive ml-1">Change Operations </span>
            </div>

            <div
              class="pointer d-flex justify-center primary--text"
              @click="unAssignOperations(item.id)"
              v-if="item.actions.includes($keys.UNASSIGN_ME)"
            >
              <v-icon color="primary" size="14"> mdi-account-minus </v-icon>
              <span class="executive ml-1">Unassign me</span>
            </div>

            <div
              class="pointer d-flex justify-center primary--text"
              @click="unAssignOperations(item.id)"
              v-if="item.actions.includes($keys.UNASSIGN_OPERATIONS)"
            >
              <v-icon color="primary" size="14"> mdi-account-minus </v-icon>
              <span class="executive ml-1">Unassign Operations</span>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="item.actions.includes($keys.ASSIGN_OPERATIONS)">
            <div
              class="pointer primary--text"
              @click="
                assignOperations(
                  item.id,
                  item.university ? item.university.id : null
                )
              "
              v-if="item.actions.includes($keys.ASSIGN_OPERATIONS)"
            >
              <v-icon color="primary" size="14"> mdi-account-plus </v-icon>
              <span class="executive ml-1">Assign Operations </span>
            </div>
          </div>
          <v-icon v-else>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.assigned_fe_ir`]="{ item }">
        <div v-if="item.fe_ir">
          {{ item.fe_ir.name }}<br />
          <div
            class="pointer primary--text"
            v-if="item.actions.includes($keys.CHANGE_FE_IR)"
            @click="assignFeIr(item.id, item.university.id)"
          >
            <v-icon color="primary" size="14"> mdi-account-edit </v-icon>
            <span class="executive ml-1">Change FE/IR </span>
          </div>
          <div
            class="pointer primary--text"
            @click="unAssignFeIr(item.id)"
            v-if="item.actions.includes($keys.UNASSIGN_FE_IR)"
          >
            <v-icon color="primary" size="14"> mdi-account-minus </v-icon>
            <span class="executive ml-1">Unassign FE/IR </span>
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.assigned_vendor`]="{ item }">
        <div v-if="item.vendor">
          {{ item.vendor.name }}<br />
          <div class="pointer primary--text" @click="assignVendor(item.id)">
            <v-icon color="primary" size="14"> mdi-account-edit </v-icon>
            <span class="executive ml-1">Change Vendor </span>
          </div>
          <div class="pointer primary--text" @click="unAssignVendor(item.id)">
            <v-icon color="primary" size="14"> mdi-account-minus </v-icon>
            <span class="executive ml-1">Unassign Vendor </span>
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            height="32px"
            color="primary"
            class="text-capitalize rounded-lg"
            v-for="action in btnActions(item)"
            :key="action.key"
            @click="actionHandler(action.key, item)"
          >
            <v-icon left v-if="action.icon">mdi-{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>

          <v-menu
            left
            offset-y
            min-width="200"
            width="200"
            nudge-bottom="10"
            v-if="menuActions(item).length"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                outlined
                color="primary"
                class="text-capitalize rounded-lg"
                width="5px"
                height="32px"
                min-width="5px"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list nav dense :outlined="$vuetify.theme.dark">
              <v-list-item
                link
                v-for="action in menuActions(item)"
                :key="action.key"
                @click="actionHandler(action.key, item)"
              >
                <v-list-item-title>{{ action.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <!-- right click menu -->
    <v-menu
      v-model="rightClickMenu.show"
      :position-x="rightClickMenu.positionX"
      :position-y="rightClickMenu.positionY"
      transition="scale-transition"
      min-width="200"
      width="200"
      absolute
      offset-y
      v-if="
        rightClickMenu.selectedItem &&
        actions(rightClickMenu.selectedItem).length
      "
    >
      <v-list nav dense :outlined="$vuetify.theme.dark">
        <v-list-item
          link
          v-for="action in actions(rightClickMenu.selectedItem)"
          :key="action.key"
          @click="actionHandler(action.key, rightClickMenu.selectedItem)"
        >
          <v-list-item-title>{{ action.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- follow-up, on-hold,  dialog -->
    <CaseCommonDialog @submitted="reloadData" />

    <!-- upload documents dialog -->
    <UploadDocumentDialog @submitted="reloadData" />

    <!-- confirmation dialog -->
    <ConfirmationDialog @confirmed="onConfirmed" height="130px" width="130px">
      <template v-slot:subtitle>
        <span v-if="confirmation_type === 'document_received'">
          Do you really want to mark as Document Received.
        </span>
        <span v-if="confirmation_type === 'initiate_account_clearance'">
          Do you really want to Initiate Account Clearance.
        </span>
        <span v-if="confirmation_type === 'close_case'">
          Do you really want to Close this Case.
        </span>
      </template>

      <template v-slot:confirmBtnText>
        <span v-if="confirmation_type === 'document_received'">
          Yes, Mark
        </span>
        <span v-if="confirmation_type === 'initiate_account_clearance'">
          Yes, Initiate
        </span>
        <span v-if="confirmation_type === 'close_case'"> Yes, Close </span>
      </template>
    </ConfirmationDialog>

    <!-- request payouts dialog -->
    <RequestPayoutDialog @submitted="reloadData" />

    <!-- add tracking info dialog -->
    <AddTrackingInfo @submitted="reloadData" />

    <!-- operations executive and fe/ir select dialog -->
    <select-executive-modal @selected="onSelected" />

    <!-- eca status history dialog -->
    <eca-status-history-dialog />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { saveAs } from "file-saver";

import { adminHeaders } from "@/headers/caseManagement";
import CaseCommonDialog from "@/components/Dialogs/CaseCommonDialog";
import UploadDocumentDialog from "@/components/Dialogs/UploadDocumentDialog";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";
import SelectExecutiveModal from "@/components/selectExecutiveModal";
import RequestPayoutDialog from "@/components/CaseManagement/Payout/RequestDialog";
import AddTrackingInfo from "@/components/CaseManagement/AddTrackingInfo";
import EcaStatusHistoryDialog from "@/components/CaseManagement/EcaStatusHistoryDialog";

export default {
  components: {
    CaseCommonDialog,
    UploadDocumentDialog,
    ConfirmationDialog,
    SelectExecutiveModal,
    RequestPayoutDialog,
    AddTrackingInfo,
    EcaStatusHistoryDialog,
  },

  data() {
    return {
      items: [],
      loading: false,

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_count: 10,

      rightClickMenu: {
        show: false,
        positionX: 0,
        positionY: 0,
        selectedItem: null,
      },

      editDialog: {
        eca_reference_number: "",
      },

      selectedItem: null,
      confirmation_type: null,
    };
  },

  computed: {
    ...mapGetters({
      getUserDetail: "getUser",
      search: "caseManagement/search",
      filters: "caseManagement/filters",
      activeTab: "caseManagement/activeTab",
      serviceTypes: "getServiceTypes",
      getExecutiveModal: "caseManagement/getExecutiveModal",
      serviceTypeSwitch: "topNav/serviceTypeSwitch",
    }),

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },

    selectedServiceType() {
      if (this.serviceTypes.length) {
        return this.serviceTypes[this.serviceTypeSwitch.selected].key;
      }
      return this.$keys.EDUCATIONAL_DOCUMENTATION;
    },

    headers() {
      return adminHeaders[this.selectedServiceType][this.activeTab.key];
    },
  },
  watch: {
    page_count() {
      this.getCaseList();
    },

    page_number() {
      this.getCaseList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },

    selectedServiceType() {
      this.reloadData();
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    this.updateRoute();
  },

  mounted() {
    this.getCaseList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleCnfDialog: "dialogs/toggleConfirmationDialog",
      toggleCaseCommonDialog: "dialogs/toggleCaseCommonDialog",
      toggleSendQuotationDialog: "dialogs/toggleSendQuotationDialog",
      toggleAddPaymentDialog: "payments/toggleAddUpdateDialog",
      toggleUploadDocumentDialog: "dialogs/toggleUploadDocumentDialog",
      setCnfDialogBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
      payoutForm: "caseManagement/payoutForm",
      executiveModal: "caseManagement/executiveModal",
      toggleAddTrackingInfoDialog: "dialogs/toggleAddTrackingInfoDialog",
      toggleEcaStatusHistoryDialog:
        "caseManagement/toggleEcaStatusHistoryDialog",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getCaseList();
    },

    updateRoute() {
      const query = {};

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    actions(item) {
      return item.btn_actions;
    },

    btnActions(item) {
      return this.$_.filter(item.btn_actions, { show_in_menu: false });
    },

    menuActions(item) {
      return this.$_.filter(item.btn_actions, { show_in_menu: true });
    },

    openCnfDialog(item, type) {
      this.selectedItem = item;
      this.confirmation_type = type;
      this.toggleCnfDialog({ show: true });
    },

    onConfirmed() {
      if (this.confirmation_type === "document_received") {
        this.submitDocument(this.selectedItem);
      }
      if (this.confirmation_type === "initiate_account_clearance") {
        this.initiateAccountClearance(this.selectedItem);
      }
      if (this.confirmation_type === "close_case") {
        this.closeCase(this.selectedItem);
      }
    },

    openEcaStatusHistoryDialog(item) {
      this.toggleEcaStatusHistoryDialog({
        show: true,
        items: item.eca_status_history,
      });
    },

    actionHandler(key, item) {
      switch (key) {
        case "view_case": {
          this.openCaseDetail(item.id);
          break;
        }

        case "add_payment": {
          this.toggleAddPaymentDialog({
            show: true,
            id: null,
            caseId: item.id,
            type: "add",
          });
          break;
        }

        case "send_quotation": {
          this.toggleSendQuotationDialog({
            show: true,
            caseId: item.id,
            clientId: item.client.id,
          });
          break;
        }

        case "move_to_follow_up": {
          this.toggleCaseCommonDialog({
            show: true,
            caseId: item.id,
            type: this.$keys.FOLLOW_UP,
          });
          break;
        }

        case "mark_as_irrelevant": {
          this.toggleCaseCommonDialog({
            show: true,
            caseId: item.id,
            type: this.$keys.IRRELEVANT,
          });
          break;
        }

        case "send_welcome_email": {
          this.sendWelcomeEmail(item.id);
          break;
        }

        case "upload_document": {
          this.toggleUploadDocumentDialog({ show: true, caseId: item.id });
          break;
        }

        case "document_received": {
          this.openCnfDialog(item.id, "document_received");
          break;
        }

        case "assign_fe_ir": {
          this.assignFeIr(item.id, item.university ? item.university.id : null);
          break;
        }

        case "assign_vendor": {
          this.assignVendor(item.id);
          break;
        }

        case "assign_university": {
          this.assignUniversity(item.id);
          break;
        }

        case "unassign_university": {
          this.unAssignUniversity(item.id);
          break;
        }

        case "request_payout": {
          this.payoutForm({ item: item, show: true });
          break;
        }

        case "initiate_account_clearance": {
          this.openCnfDialog(item.id, "initiate_account_clearance");
          break;
        }

        case "add_tracking_info": {
          this.toggleAddTrackingInfoDialog({ show: true, item: item });
          break;
        }

        case "close_case": {
          this.openCnfDialog(item.id, "close_case");
          break;
        }

        case "invoice_send": {
          this.sendInvoice(item.id);
          break;
        }

        case "invoice_download": {
          this.downloadInvoice(item.id);
          break;
        }

        case "move_to_on_hold": {
          this.toggleCaseCommonDialog({
            show: true,
            caseId: item.id,
            type: this.$keys.ON_HOLD,
          });
          break;
        }

        case "cancel_case": {
          this.toggleCaseCommonDialog({
            show: true,
            caseId: item.id,
            type: this.$keys.CANCELLED,
          });
          break;
        }

        default:
          break;
      }
    },

    submitDocument(caseId) {
      const onSuccess = () => {
        this.toggleCnfDialog({ show: false });
        this.reloadData();
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      const params = {
        case_id: caseId,
      };

      return this.$request(this.$keys.GET, this.$urls.case.document.submit, {
        params,
        onSuccess,
        onFinally,
      });
    },

    assignSales(case_id) {
      this.executiveModal({
        id: case_id,
        show: true,
        type: "assign_sales",
      });
    },

    unAssignSales(case_id) {
      let data = {
        case: case_id,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.POST, this.$urls.case.unAssignSales, {
        data: data,
        onSuccess: successHandler,
      });
    },

    assignOperations(case_id, university_id) {
      this.executiveModal({
        id: case_id,
        show: true,
        type: "assign_operations",
        university_id: university_id,
      });
    },

    unAssignOperations(case_id) {
      let data = {
        case: case_id,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.POST, this.$urls.case.unAssignOperations, {
        data: data,
        onSuccess: successHandler,
      });
    },

    assignFeIr(case_id, university_id) {
      this.executiveModal({
        id: case_id,
        show: true,
        type: "assign_fe_ir",
        university_id: university_id,
      });
    },

    unAssignFeIr(case_id) {
      let data = {
        case: case_id,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.POST, this.$urls.case.unAssignFeIr, {
        data: data,
        onSuccess: successHandler,
      });
    },

    assignVendor(caseId) {
      this.executiveModal({
        id: caseId,
        show: true,
        type: "assign_vendor",
        university_id: null,
      });
    },

    unAssignVendor(caseId) {
      const data = {
        case: caseId,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.POST, this.$urls.case.unAssignVendor, {
        data: data,
        onSuccess: successHandler,
      });
    },

    assignUniversity(caseId) {
      const params = {
        case_id: caseId,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.GET, this.$urls.case.assignUniversity, {
        params,
        onSuccess: successHandler,
      });
    },

    unAssignUniversity(caseId) {
      const params = {
        case_id: caseId,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.GET, this.$urls.case.unAssignUniversity, {
        params,
        onSuccess: successHandler,
      });
    },

    onSelected(selected_id) {
      const data = {
        case: this.getExecutiveModal.id,
      };

      let url = null;

      switch (this.getExecutiveModal.type) {
        case "assign_operations": {
          url = this.$urls.case.assignOperations;
          data["operations"] = selected_id;
          break;
        }

        case "assign_sales": {
          url = this.$urls.case.assignSales;
          data["sales"] = selected_id;
          break;
        }

        case "assign_fe_ir": {
          url = this.$urls.case.assignFeIr;
          data["fe_ir"] = selected_id;
          break;
        }

        case "assign_vendor": {
          url = this.$urls.case.assignVendor;
          data["vendor"] = selected_id;
          break;
        }

        default:
          return;
      }

      const successHandler = () => {
        this.reloadData();
        this.executiveModal({
          id: "",
          show: false,
          type: "",
          university_id: "",
        });
      };

      this.$request(this.$keys.POST, url, {
        data: data,
        onSuccess: successHandler,
      });
    },

    initiateAccountClearance(caseId) {
      const onSuccess = () => {
        this.toggleCnfDialog({ show: false });
        this.reloadData();
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      const params = {
        case_id: caseId,
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.case.accountClearance.initiate,
        {
          params,
          onSuccess,
          onFinally,
        }
      );
    },

    closeCase(caseId) {
      const onSuccess = () => {
        this.toggleCnfDialog({ show: false });
        this.reloadData();
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      const data = {
        case: caseId,
      };

      return this.$request(this.$keys.POST, this.$urls.case.close, {
        data,
        onSuccess,
        onFinally,
      });
    },

    getCaseList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.items = res.data.data;
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.$emit("reload-count");
      };

      const onFinally = () => {
        this.loading = false;
      };

      const filters = this.filters;
      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        case_status: this.activeTab.key,
        search: this.search,
        date_filter_type: filters.date_filter_type,
        source: filters.source ? filters.source.key : "",
        executive_id: filters.executive ? filters.executive.id : null,
        university_id: filters.university ? filters.university.id : null,
        eca_body_id: filters.eca ? filters.eca.id : null,
        start_date: filters.start_date,
        end_date: filters.end_date,
        service_type: this.selectedServiceType,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    openCaseDetail(id) {
      this.$router.push({
        name: "caseDetail",
        params: {
          id: id,
        },
      });
    },

    onItemDoubleClick(event, { item }) {
      this.openCaseDetail(item.id);
    },

    onEdit(params, data) {
      const onSuccess = () => {
        this.getCaseList();
      };

      return this.$request(this.$keys.PATCH, this.$urls.case.update, {
        params,
        data,
        onSuccess,
      });
    },

    onSave(key, item) {
      const formData = {};
      const param = {
        case_id: item.id,
      };

      switch (key) {
        case "eca_reference_number": {
          formData["eca_reference_number"] =
            this.editDialog.eca_reference_number;
          this.onEdit(param, formData);
          break;
        }
      }
    },

    onRightClick(event, { item }) {
      event.preventDefault();
      this.rightClickMenu.selectedItem = item;
      this.rightClickMenu.show = false;
      this.rightClickMenu.positionX = event.clientX;
      this.rightClickMenu.positionY = event.clientY;
      this.$nextTick(() => {
        this.rightClickMenu.show = true;
      });
    },

    downloadInvoice(case_id) {
      this.showSnackbar({
        text: "Please wait, download is in progress.",
        color: "success",
      });
      let params = {
        case_id: case_id,
      };
      const successHandler = (res) => {
        let filename = "invoice.pdf";
        if (res.headers["content-disposition"])
          [, filename] = res.headers["content-disposition"].split("filename=");
        saveAs(res.data, filename);
      };
      const failureHandler = () => {
        this.showSnackbar({
          text: "Something went wrong",
          color: "error",
        });
      };
      return this.$request(this.$keys.GET, this.$urls.case.downloadInvoice, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        responseType: "blob",
      });
    },

    sendInvoice(case_id) {
      let params = {
        case_id: case_id,
      };

      const onSuccess = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
      };

      return this.$request(this.$keys.GET, this.$urls.case.sendInvoice, {
        params,
        onSuccess,
      });
    },

    sendWelcomeEmail(case_id) {
      let params = {
        case_id: case_id,
      };

      const onSuccess = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
      };

      return this.$request(this.$keys.GET, this.$urls.case.sendWelcomeEmail, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.executive {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  text-decoration-line: underline;
}
</style>

<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="465px"
    content-class="rounded-xl"
    @keydown.esc="modalClosed"
    :retain-focus="false"
  >
    <Loading v-if="loading" />
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6" v-if="type === 'edit'">Edit Payment Record</div>
        <div v-else class="text-h6">Add Payment Record</div>
        <v-btn icon @click="modalClosed()">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <div v-if="!caseId">
            <v-autocomplete
              clearable
              auto-select-first
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              append-icon="mdi-chevron-down"
              :rules="[$rules.required]"
              v-model="form.client"
              :items="client_list"
              @change="getCaseList"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              label="Select Client"
              class="rounded-lg"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.user.mobile_display }} |
                    {{ data.item.user.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>

            <v-autocomplete
              auto-select-first
              clearable
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              :rules="[$rules.required]"
              append-icon="mdi-chevron-down"
              v-model="form.case"
              :items="case_list"
              :disabled="!form.client"
              item-text="case_id"
              return-object
              flat
              outlined
              dense
              label="Select Case"
              class="rounded-lg"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.case_id }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.service.name }} | Pending Amount:
                    {{ data.item.pending_amount }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>

          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
            offset-y
            nudge-top="20"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-text-field
                v-model="form.payment_date"
                label="Payment date"
                v-bind="attrs"
                :rules="[$rules.required]"
                height="42px"
                solo
                outlined
                dense
                flat
                append-icon="mdi-calendar"
                v-on="on"
                readonly
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="form.payment_date"
              no-title
              :max="maxDate"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-select
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            :rules="[$rules.required]"
            append-icon="mdi-chevron-down"
            v-model="form.mode"
            :items="payment_mode_list"
            item-text="name"
            item-value="id"
            flat
            outlined
            dense
            label="Select Payment Mode"
            class="rounded-lg"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">
                  {{ data.item.name }} -
                  {{ data.item.type }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

          <v-text-field
            flat
            outlined
            dense
            hide-spin-buttons
            type="number"
            label="Amount"
            v-model="form.amount"
            @keydown="$rules.blockInvalidChar"
            @change="validateNegativeNumber('amount')"
            :rules="[
              $rules.required,
              $rules.validateNegativeNumber,
              validateMaxAmount,
            ]"
            class="rounded-lg"
            :max="maxAmount"
          ></v-text-field>

          <v-textarea
            :rows="2"
            type="text"
            v-model="form.remarks"
            flat
            outlined
            dense
            label="Remarks"
            class="rounded-lg"
          ></v-textarea>

          <div class="mt-5" v-if="type === $keys.ADD">
            <div>Attach Document (Optional)</div>
            <div class="file-input-container mt-2">
              <v-file-input
                accept="image/*, application/pdf"
                id="file-input"
                v-show="false"
                @change="onFileChange"
              ></v-file-input>
              <label for="file-input">
                <div class="file-input d-flex align-center justify-center">
                  <div v-if="form.file">
                    <v-img
                      :src="form.previewFile"
                      height="120"
                      width="120"
                      v-if="form.file.type.startsWith('image/')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon v-else size="50">mdi-file</v-icon>
                  </div>
                  <v-icon v-else size="40">mdi-upload</v-icon>
                </div>
              </label>
              <v-icon
                size="20"
                v-if="form.file"
                color="primary"
                @click="removeFile"
                class="file-remover"
                >mdi-close-circle</v-icon
              >
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="text-capitalize px-8 my-2 rounded-lg"
          height="40px"
          color="primary"
          @click="submit"
          :loading="btnLoading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      dateMenu: false,

      client_list: [],
      case_list: [],
      payment_mode_list: [],
      paymentDetail: null,

      form: {
        client: null,
        case: null,
        mode: null,
        payment_date: this.$moment().format("YYYY-MM-DD"),
        amount: 0,
        file: null,
        previewFile: null,
        remarks: "",
      },
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    "form.case"(value) {
      if (!value) return;
      this.form.amount = value.pending_amount;
    },
  },

  computed: {
    ...mapGetters({
      addUpdateDialog: "payments/addUpdateDialog",
    }),

    show: {
      get() {
        return this.addUpdateDialog.show;
      },
      set(value) {
        this.toggleAddUpdateDialog({ show: value });
      },
    },

    caseId() {
      return this.addUpdateDialog.caseId;
    },

    type() {
      return this.addUpdateDialog.type;
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    maxAmount() {
      if (this.form.case) return this.form.case.pending_amount;
      return null;
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleAddUpdateDialog: "payments/toggleAddUpdateDialog",
    }),

    validateNegativeNumber(field) {
      if (this.form[field] < 0) {
        this.form[field] = 0;
      }
    },

    validateMaxAmount(value) {
      if (this.form.case && value > this.form.case.pending_amount)
        return "Amount can not be greater than quotation amount";
      return true;
    },

    async openModal() {
      this.loading = true;

      await this.getPaymentMode();

      if (this.caseId) {
        this.getCaseDetail();
      } else {
        await this.getClientList();
      }

      if (this.type === this.$keys.EDIT) {
        await this.getPaymentDetail();
        const data = this.paymentDetail;
        this.form = {
          payment_date: data.payment_date
            ? this.$moment(data.payment_date).format("YYYY-MM-DD")
            : null,
          mode: data.mode ? data.mode.id : null,
          amount: data.amount,
          remarks: data.remarks,
        };
      }

      this.loading = false;
    },

    modalClosed() {
      this.form = {
        client: null,
        case: null,
        mode: null,
        payment_date: this.$moment().format("YYYY-MM-DD"),
        amount: 0,
        file: null,
        previewFile: null,
        remarks: "",
      };
      this.$refs.form.reset();
      this.toggleAddUpdateDialog({ show: false });
    },

    onFileChange(file) {
      if (file) {
        this.form.file = file;
        if (file.type.startsWith("image/")) {
          this.form.previewFile = URL.createObjectURL(file);
        }
        URL.revokeObjectURL(file);
      } else {
        this.form.previewFile = null;
      }
    },

    removeFile() {
      this.form.file = null;
      this.form.previewFile = null;
    },

    getClientList() {
      const params = {
        apply_pagination: false,
        list_type: "add_case_payment",
        query: "{id, user {id, email, mobile_display}, name}",
      };

      const onSuccess = (res) => {
        this.client_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.client.list, {
        params,
        onSuccess,
      });
    },

    getCaseList() {
      const onSuccess = (res) => {
        this.case_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
        client_id: this.form.client,
        list_type: "add_case_payment",
      };

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
      });
    },

    getCaseDetail() {
      const onSuccess = (res) => {
        this.form.case = res.data.data;
      };

      const params = {
        case_id: this.caseId,
      };

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
      });
    },

    getPaymentMode() {
      const onSuccess = (res) => {
        this.payment_mode_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.paymentMode.list, {
        params,
        onSuccess,
      });
    },

    getPaymentDetail() {
      const onSuccess = (res) => {
        this.paymentDetail = res.data.data;
      };

      const params = {
        payment_id: this.addUpdateDialog.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.payment.detail, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        if (this.form.amount <= 0) {
          this.showSnackbar({
            text: "Amount can not be 0.",
            color: "error",
          });
          this.btnLoading = false;
          return;
        }

        const params = {};
        const data = new FormData();

        data.append("mode", this.form.mode);
        data.append("amount", this.form.amount);
        data.append("payment_date", this.form.payment_date);
        data.append("remarks", this.form.remarks);

        if (this.form.file) {
          data.append("document", this.form.file);
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        if (this.type === this.$keys.EDIT) {
          params["payment_id"] = this.addUpdateDialog.id;
          return this.$request(
            this.$keys.PATCH,
            this.$urls.case.payment.update,
            {
              params,
              data,
              onSuccess,
              onFinally,
            }
          );
        }

        data.append("case", this.form.case.id);
        return this.$request(this.$keys.POST, this.$urls.case.payment.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
<style scoped>
.file-input-container {
  position: relative;
  border: 1px dashed #9e9e9e;
  box-sizing: border-box;
  border-radius: 2px;
  width: 130px;
  height: 130px;
}

.file-input {
  height: 100%;
  cursor: pointer;
}

.file-remover {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
</style>
